import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import NavBar from '../ui/NavBar';

export class Privacy extends Component {
    getBackgroundStyle = () => {
        const { home_settings } = this.props.bot;
        if (home_settings.backgroundType === 'solid-color') {
            return { backgroundColor: home_settings.backgroundColor || '#1a202c' };
        } else if (home_settings.backgroundType === 'linear-gradient') {
            const [color1, color2] = home_settings.gradientColors || ['#1a202c', '#2d3748'];
            const angle = home_settings.gradientAngle || 45;
            return { background: `linear-gradient(${angle}deg, ${color1}, ${color2})` };
        }
        return { backgroundColor: '#1a202c' }; // Default background
    };

    getFontStyle = () => {
        const { home_settings } = this.props.bot;
        return {
            fontFamily: `'${home_settings.font}', sans-serif`,
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale'
        };
    };

    render() {
        const backgroundStyle = this.getBackgroundStyle();
        const fontStyle = this.getFontStyle();
        const { homeType } = this.props.bot.home_settings || {};

        return (
            <div className="min-h-screen flex flex-col" style={{ ...backgroundStyle, ...fontStyle }}>
                <Helmet>
                    <title>Privacy Policy - {this.props.bot.name}</title>
                    {this.props.bot.home_settings.font && (
                        <link
                            href={`https://fonts.googleapis.com/css?family=${this.props.bot.home_settings.font.replace(' ', '+')}&display=swap`}
                            rel="stylesheet"
                        />
                    )}
                </Helmet>



                <div className="container mx-auto px-4 py-8 text-white flex-grow">
                    <h1 className="text-3xl font-bold mb-6">Privacy Policy for {this.props.bot.name}</h1>

                    <p className="mb-4">Thank you for using {this.props.bot.name}! We take your privacy seriously and are committed to safeguarding the information we collect. This Privacy Policy outlines how we collect, use, and protect your data when you interact with {this.props.bot.name}.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">1. Information We Collect</h2>
                    <p className="mb-4">{this.props.bot.name} may collect the following types of information:</p>
                    <ul className="list-disc list-inside mb-4">
                        <li><strong>User Data:</strong> We collect user data such as Discord user IDs, server IDs, and channel IDs to provide essential bot functionalities.</li>
                        <li><strong>Message Content:</strong> Some bot commands may require us to process message content. This data is only used to fulfill the command requested by the user and is not stored permanently.</li>
                        <li><strong>Server Data:</strong> We may collect information about your Discord server to provide configuration settings and customized features.</li>
                    </ul>
                    <p className="mb-4">We only collect data that is necessary to provide the services offered by {this.props.bot.name}, and all data is handled in compliance with applicable privacy laws.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">2. How We Use Your Information</h2>
                    <p className="mb-4">The information we collect is used for the following purposes:</p>
                    <ol className="list-decimal list-inside mb-4">
                        <li><strong>To Provide and Improve Our Services:</strong> We use the collected data to ensure {this.props.bot.name} functions properly, to respond to user requests, and to enhance the features we provide.</li>
                        <li><strong>Diagnostics and Analytics:</strong> We may use data for troubleshooting technical issues and to understand how users interact with {this.props.bot.name} to improve its performance and features.</li>
                        <li><strong>Support:</strong> If you contact us for support, we may use your data to respond to your questions or concerns.</li>
                    </ol>
                    <p className="mb-4">We do not sell or share your data with third parties for marketing or advertising purposes.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">3. Data Retention</h2>
                    <p className="mb-4">We only retain user data for as long as it is needed to provide our services. Certain data, such as command responses or logs, may be kept temporarily for diagnostic and quality assurance purposes. Once the data is no longer required, it is deleted or anonymized.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">4. Data Sharing</h2>
                    <p className="mb-4">{this.props.bot.name} does not share your personal information with third parties, except as required by law or to protect the rights and safety of our users. We may use third-party services (e.g., hosting providers) that assist in delivering our services, and these providers are obligated to protect your data under similar privacy obligations.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">5. Security</h2>
                    <p className="mb-4">We take reasonable measures to protect the information we collect from unauthorized access, alteration, or destruction. We implement administrative, technical, and physical safeguards to ensure the security of your data. However, please understand that no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">6. Children's Privacy</h2>
                    <p className="mb-4">{this.props.bot.name} is intended for users who are at least 13 years old, in compliance with Discord's Terms of Service. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected such information, we will take immediate steps to delete it.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">7. User Rights</h2>
                    <p className="mb-4">You have the right to:</p>
                    <ol className="list-decimal list-inside mb-4">
                        <li><strong>Access Your Data:</strong> Request information about the data we hold about you.</li>
                        <li><strong>Delete Your Data:</strong> Request the deletion of your data if it is no longer necessary for the purposes for which it was collected.</li>
                        <li><strong>Restrict Processing:</strong> Object to or request the restriction of certain processing activities.</li>
                    </ol>

                    <h2 className="text-2xl font-bold mt-6 mb-4">8. Changes to This Privacy Policy</h2>
                    <p className="mb-4">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any changes will be posted here, and we will provide notice of significant updates. Continued use of {this.props.bot.name} following such changes constitutes your acceptance of the updated policy.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">9. Contact Information</h2>
                    <p className="mb-4">
                        If you have any questions or concerns about this Privacy Policy or how we handle your data, 
                        please contact us at{' '}
                        {this.props.bot.extra_pages?.contactInfo || '[contact information not provided]'}.
                    </p>


                    <p className="mt-8">By using {this.props.bot.name}, you acknowledge that you have read and understood this Privacy Policy and agree to the collection and use of your information as outlined above.</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
});

export default connect(mapStateToProps)(Privacy);
