import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import NavBar from '../ui/NavBar';

export class TOS extends Component {
    getBackgroundStyle = () => {
        const { home_settings } = this.props.bot;
        if (home_settings.backgroundType === 'solid-color') {
            return { backgroundColor: home_settings.backgroundColor || '#1a202c' };
        } else if (home_settings.backgroundType === 'linear-gradient') {
            const [color1, color2] = home_settings.gradientColors || ['#1a202c', '#2d3748'];
            const angle = home_settings.gradientAngle || 45;
            return { background: `linear-gradient(${angle}deg, ${color1}, ${color2})` };
        }
        return { backgroundColor: '#1a202c' }; // Default background
    };

    getFontStyle = () => {
        const { home_settings } = this.props.bot;
        return {
            fontFamily: `'${home_settings.font}', sans-serif`,
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale'
        };
    };

    render() {
        const backgroundStyle = this.getBackgroundStyle();
        const fontStyle = this.getFontStyle();
        const { homeType } = this.props.bot.home_settings || {};

        return (
            <div className="min-h-screen flex flex-col" style={{ ...backgroundStyle, ...fontStyle }}>
                <Helmet>
                    <title>Terms of Service - {this.props.bot.name}</title>
                    {this.props.bot.home_settings.font && (
                        <link
                            href={`https://fonts.googleapis.com/css?family=${this.props.bot.home_settings.font.replace(' ', '+')}&display=swap`}
                            rel="stylesheet"
                        />
                    )}
                </Helmet>

                {/* {homeType === 'advanced' && <NavBar bot={this.props.bot} />} */}

                <div className="container mx-auto px-4 py-8 text-white flex-grow">
                    <h1 className="text-3xl font-bold mb-6">Terms of Service for {this.props.bot.name}</h1>

                    <p className="mb-4">Welcome to {this.props.bot.name}! By using {this.props.bot.name}, you agree to be bound by the following terms of service ("Terms"). These Terms apply to all users, customers, and individuals interacting with the bot and its related services. Please read them carefully. Your continued use of {this.props.bot.name} indicates your acceptance of these Terms, and it is important that you understand all your rights and obligations.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">1. Acceptance of Terms</h2>
                    <p className="mb-4">By inviting {this.props.bot.name} to your Discord server or using its services in any way, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you do not agree to any part of these Terms, you must not use {this.props.bot.name}. These Terms constitute a legally binding agreement between you and {this.props.bot.name}'s developers. If you have any questions about these Terms, please contact us before using the bot.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">2. Description of Service</h2>
                    <p className="mb-4">{this.props.bot.name} provides Discord server management and interaction features, such as moderation tools, automated messages, utility commands, entertainment, and much more. These features are offered "as-is" and are subject to updates and changes at any time. We strive to continuously improve {this.props.bot.name} and add new features that enhance user experience, but we do not guarantee that any specific feature or functionality will always be available.</p>
                    <p className="mb-4">Our services include, but are not limited to, moderation, entertainment, and information retrieval functions that can be tailored to fit the needs of different Discord communities. As we expand the capabilities of {this.props.bot.name}, we reserve the right to modify, remove, or enhance these features without prior notice.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">3. User Obligations</h2>
                    <ol className="list-decimal list-inside mb-4">
                        <li className="mb-2"><strong>Age Requirement:</strong> You must be at least 13 years old to use Discord and {this.props.bot.name}. By using {this.props.bot.name}, you confirm that you meet this age requirement and that you understand and accept the responsibilities of managing a Discord server.</li>
                        <li className="mb-2"><strong>Legal Compliance:</strong> You agree to comply with all local, state, national, and international laws, regulations, and rules in connection with your use of {this.props.bot.name}. You are solely responsible for ensuring that your use of {this.props.bot.name} is compliant with applicable laws, including but not limited to those concerning privacy, intellectual property, and online conduct.</li>
                        <li className="mb-2"><strong>Appropriate Usage:</strong> You must not use {this.props.bot.name} in a way that may cause harm, inconvenience, or harassment to other users. You must also not use the bot for spamming, malicious behavior, or to distribute illegal content. Misuse of the bot, such as exploiting vulnerabilities or bypassing restrictions, will result in termination of access.</li>
                    </ol>

                    <h2 className="text-2xl font-bold mt-6 mb-4">4. Data Collection and Privacy</h2>
                    <p className="mb-4">{this.props.bot.name} may collect certain data necessary to provide its services, including but not limited to:</p>
                    <ul className="list-disc list-inside mb-4">
                        <li>Discord user IDs, server IDs, and channel IDs.</li>
                        <li>Message content required for executing specific bot commands.</li>
                    </ul>
                    <p className="mb-4">We do <strong>not</strong> collect or store any personally identifiable information beyond what is necessary for bot functionality. {this.props.bot.name} only collects data to the extent required to provide essential services, and all data collected is handled in accordance with applicable privacy laws.</p>
                    <p className="mb-4">By using {this.props.bot.name}, you acknowledge that your interactions with the bot may be logged for diagnostic and service improvement purposes. We are committed to securing user data and protecting privacy. Logs are stored securely, and only authorized personnel have access to this data. For more information, refer to our Privacy Policy.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">5. Changes to the Service</h2>
                    <p className="mb-4">We reserve the right to modify or discontinue, temporarily or permanently, the features or services provided by {this.props.bot.name} without prior notice. We are not liable for any changes, suspensions, or termination of services. {this.props.bot.name} may also introduce new features, limit existing functionalities, or remove outdated features without liability to users.</p>
                    <p className="mb-4">We endeavor to inform users of major changes where possible, but it is ultimately the user's responsibility to remain informed of any changes. We encourage you to follow our official channels for updates and announcements regarding {this.props.bot.name}.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">6. Service Limitations and Availability</h2>
                    <p className="mb-4">While we aim to provide consistent and uninterrupted service, we cannot guarantee that {this.props.bot.name} will always be available. Downtime may occur due to maintenance, updates, or unforeseen technical issues. We are not responsible for any inconvenience or loss that may arise due to such interruptions. This includes but is not limited to server outages, network failures, and third-party API disruptions.</p>
                    <p className="mb-4">You understand and agree that {this.props.bot.name} may be temporarily unavailable during routine maintenance or in the case of an emergency. In the event of any prolonged outage, we will make reasonable efforts to restore service as quickly as possible.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">7. Disclaimer of Warranties</h2>
                    <p className="mb-4">{this.props.bot.name} is provided "as-is" without warranties of any kind, either expressed or implied. We do not warrant that the bot will be free from errors, vulnerabilities, or interruptions, nor do we guarantee any specific outcomes from the use of {this.props.bot.name}. All use of {this.props.bot.name} is at your own risk, and we disclaim all warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                    <p className="mb-4">We cannot guarantee that {this.props.bot.name} will meet your specific requirements or that it will operate without interruption or error. Any reliance on {this.props.bot.name} is at your own discretion, and we disclaim liability for any loss or damage that may result.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">8. Limitation of Liability</h2>
                    <p className="mb-4">To the maximum extent permitted by applicable law, {this.props.bot.name} and its owners, developers, and affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to data loss, server disruption, or unauthorized access resulting from your use of {this.props.bot.name}. We are not responsible for any damages arising from delays, bugs, or service interruptions.</p>
                    <p className="mb-4">{this.props.bot.name} shall not be liable for any third-party conduct or content, including but not limited to actions taken by other Discord users, server members, or external service providers. In no event shall the liability of {this.props.bot.name} exceed the total amount paid by the user, if any, for using {this.props.bot.name}.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">9. Termination</h2>
                    <p className="mb-4">We reserve the right to terminate or suspend access to {this.props.bot.name} at any time, with or without prior notice, for violating these Terms or for any other reason. You may also remove {this.props.bot.name} from your server at any time if you no longer agree with these Terms. Termination of access to {this.props.bot.name} will result in the removal of any user-generated data associated with your account.</p>
                    <p className="mb-4">In the case of termination, the affected user will not be entitled to any refunds or compensation. Any rights granted to you under these Terms will immediately cease, and any continued use of {this.props.bot.name} is strictly prohibited.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">10. Contact Information</h2>
                    <p className="mb-4">
                        If you have any questions or concerns about these Terms or the use of {this.props.bot.name}, 
                        please contact us at{' '}
                        {this.props.bot.extra_pages?.contactInfo || '[contact information not provided]'}.
                        We are committed to providing prompt responses and addressing user issues or inquiries in a timely manner.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">11. Updates to the Terms of Service</h2>
                    <p className="mb-4">We reserve the right to update or modify these Terms at any time. Users will be notified of significant changes, and continued use of {this.props.bot.name} constitutes acceptance of the updated Terms. It is the responsibility of the user to review these Terms periodically. Notifications of updates will be made through official communication channels or directly within the bot.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">12. User-Generated Content</h2>
                    <p className="mb-4">Any content generated by users while interacting with {this.props.bot.name}, including but not limited to messages, commands, and media, must comply with these Terms and Discord's Community Guidelines. We reserve the right to remove or restrict access to any content that violates these guidelines or is deemed inappropriate. We are not responsible for monitoring user-generated content, but we may act upon reports of abuse or misuse.</p>
                    <p className="mb-4">Users are responsible for ensuring that all user-generated content is lawful and does not infringe on the rights of others. Any content deemed in violation of these Terms may be deleted without warning, and repeated violations may lead to termination of access to {this.props.bot.name}.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">13. Intellectual Property</h2>
                    <p className="mb-4">All content, branding, and features of {this.props.bot.name} are protected by applicable copyright, trademark, and other intellectual property laws. You are granted a limited, non-exclusive, and non-transferable license to use {this.props.bot.name} for its intended purpose. You must not copy, modify, distribute, or create derivative works based on any part of {this.props.bot.name} without prior written permission.</p>
                    <p className="mb-4">Unauthorized use of {this.props.bot.name}'s branding, name, or features may result in legal action. All rights not expressly granted to you are reserved by the developers of {this.props.bot.name}.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">14. Third-Party Services</h2>
                    <p className="mb-4">{this.props.bot.name} may interact with third-party services or APIs to provide certain features. We do not control and are not responsible for the content, privacy policies, or practices of any third-party services. By using {this.props.bot.name}, you acknowledge and agree that we are not liable for any damages or losses caused by your use of such third-party services.</p>
                    <p className="mb-4">If any third-party service requires you to accept additional terms of use, you are solely responsible for understanding and agreeing to those terms. {this.props.bot.name} is not responsible for any agreements or transactions entered into between you and third-party providers.</p>

                    <h2 className="text-2xl font-bold mt-6 mb-4">15. Indemnification</h2>
                    <p className="mb-4">You agree to indemnify and hold harmless {this.props.bot.name}, its developers, affiliates, and partners from any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of your use of {this.props.bot.name}, your violation of these Terms, or your infringement of any rights of another person or entity. This includes claims arising from content you submit, post, transmit, or make available through {this.props.bot.name}.</p>
                    <p className="mb-4">If any third-party claims arise as a result of your use of {this.props.bot.name}, you agree to cooperate fully in the defense of such claims and to bear any related costs or liabilities. We reserve the right, at our own expense, to assume exclusive control of the defense of any matter subject to indemnification.</p>



                    <p className="mt-8">By inviting or using {this.props.bot.name} on your Discord server, you agree to comply with and be bound by these Terms of Service. Continued use of {this.props.bot.name} constitutes your acceptance of any modifications or updates to these Terms.</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
});

export default connect(mapStateToProps)(TOS);
